/* eslint-disable no-unused-vars, prettier/prettier, jsx-quotes */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Text,
  Card,
  Icon,
  ContactCard,
  StampCard,
  // Badge,
  Form,
} from 'tabler-react';
import { Subheading } from '@contentful/f36-typography';
import { ButtonGroup } from '@contentful/f36-button';
import { Stack } from '@contentful/f36-core';
import { Tooltip } from '@contentful/f36-tooltip';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import authActions from '../../../redux/reducers/auth';

function AdminApps() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // eslint-disable-next-line camelcase
  const { lastname, phone, name, id: profileId } = useSelector((state) => state.profile);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Washn Admin Apps</Card.Title>
            </Card.Header>
          </Card>
          <Grid.Row cards deck>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Stripe</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS">
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <div
                  className="omni-calculator"
                  data-calculator="finance/margin"
                  data-width="300"
                  data-config="{}"
                  data-currency="USD"
                  data-show-row-controls="false"
                  data-version="3"
                  data-t="1677780412480"
                >
                  <div className="omni-calculator-header">Margin Calculator</div>
                  <div className="omni-calculator-footer">
                    <a
                      href="https://www.omnicalculator.com/finance/margin"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="Omni"
                        className="omni-calculator-logo"
                        src="https://cdn.omnicalculator.com/embed/omni-calculator-logo-long.svg"
                      />
                    </a>
                  </div>
                </div>
                <Card.Footer flexWrap="wrap" className="text-left" />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Ortto</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer flexWrap="wrap" className="text-left" />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Pipe Drive</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer className="text-left" flexWrap="wrap">
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Apollo</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS" mar>
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Intercom</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer>
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Jira</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS">
                      <Tooltip
                        placement="top"
                        id="tooltip-1"
                        content="View and manage your billing account."
                      >
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer>
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row cards deck>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">OpenPhone</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS">
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer flexWrap="wrap" className="text-left" />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Zoom</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer flexWrap="wrap" className="text-left" />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Fossa</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer className="text-left" flexWrap="wrap">
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Apollo</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS" mar>
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer />
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">StackHawk</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                      <Icon prefix="fe" name="info" />
                    </Tooltip>
                  </Card.Options>
                </Card.Header>
                <Card.Footer>
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Osona</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS">
                      <Tooltip
                        placement="top"
                        id="tooltip-1"
                        content="View and manage your billing account."
                      >
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer>
                  <ButtonGroup variant="spaced" spacing="spacingM" />
                </Card.Footer>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(AdminApps);
