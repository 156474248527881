// StripeConnectComponent.jsx
/* eslint-disable react/no-unknown-property, spaced-comment, no-else-return */
import React, { useState, useEffect } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectPayments,
  ConnectComponentsProvider,
  ConnectPayouts,
} from '@stripe/react-connect-js';

function StripeConnectComponent() {
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  const fetchClientSecret = async (account) => {
    try {
      // Fetch the AccountSession client secret from http://localhost:3005/dev/account_session
      const response = await fetch('http://localhost:3005/dev/account_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ account }),
      });

      if (!response.ok) {
        // Handle errors on the client side here
        const { error } = await response.json();
        console.error('An error occurred: ', error);
        document.querySelector('#error').removeAttribute('hidden');
        return null;
      } else {
        const { client_secret: clientSecret } = await response.json();
        document.querySelector('#error').setAttribute('hidden', '');
        return clientSecret;
      }
    } catch (error) {
      console.error('An error occurred during fetch: ', error);
      return null;
    }
  };

  useEffect(() => {
    const initializeConnect = async () => {
      const account = 'acct_1L45YhRgD04T4P5n'; // Replace with the desired account information
      console.log('Account:', account);

      const secret = await fetchClientSecret(account);

      if (secret) {
        const instance = loadConnectAndInitialize({
          publishableKey: 'pk_test_YGCcFFL8A8hxzDf7mpNykDdS',
          clientSecret: secret, // Use the client secret returned by fetchClientSecret
        });

        setStripeConnectInstance(instance);
      }
    };

    initializeConnect();
  }, []);

  if (!stripeConnectInstance) {
    // Render a loading state or handle accordingly
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectPayments />
        <ConnectPayouts />
      </ConnectComponentsProvider>
    </div>
  );
}

export default StripeConnectComponent;
