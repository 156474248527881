import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { GlobalStyles } from '@contentful/f36-core';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback="An error has occurred">
      {/**
      <GlobalStyles />
       */}
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
