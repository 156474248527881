import React, { useState } from 'react';
import { /* useHistory, */ withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form } from 'tabler-react';
import { Button, IconButton } from '@contentful/f36-button';
import { FormControl, TextInput } from '@contentful/f36-forms';
import { WorkflowsIcon } from '@contentful/f36-icons';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SiteWrapper from '../../../../wrappers/SiteWrapper';
import providersActions from '../../../../redux/reducers/providers';
// import authActions from '../../../../redux/reducers/auth';

function MembershipCreateUpdate({
  location: {
    state: { create, provider },
  },
}) {
  const dispatch = useDispatch();
  // const history = useHistory();

  const fetchProduct = (productId) => {
    setProductId(productId);
    dispatch(
      providersActions.getProduct({ productId, providerStripeAccount: provider.stripe_account }),
    );
  };
  const [productId, setProductId] = useState('');

  const stripeProductInfo = useSelector((state) => state.providers.stripeProductInfo);
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            enableReinitialize
            initialValues={{
              productId,
              stripePriceId: stripeProductInfo?.stripe_price_id || '',
              name: stripeProductInfo?.name || '',
              description: stripeProductInfo?.description || '',
              price: stripeProductInfo?.default_price || '',
              statementDescriptor: stripeProductInfo?.statement_descriptor || '',
              washnFeePercentage: 0,
            }}
            onSubmit={(values) =>
              dispatch(
                providersActions.insertMembership({
                  ...values,
                  providerId: provider.id,
                }),
              )
            }
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <Card.Header className="text-left">
                    <Card.Title>
                      {create
                        ? `Adding membership for provider location "${provider.name}"`
                        : 'Editing membership'}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col md={6} auto className="col-12">
                        <FormControl>
                          <FormControl.Label>Product Id</FormControl.Label>
                          <TextInput.Group spacing="spacingS">
                            <TextInput
                              type="text"
                              placeholder="Product Id"
                              name="productId"
                              onChange={handleChange}
                              value={values.productId}
                            />
                            <IconButton
                              variant="positive"
                              icon={<WorkflowsIcon />}
                              aria-label="Unlock"
                              onClick={() => fetchProduct(values.productId)}
                              isDisabled={!values.productId}
                            />
                          </TextInput.Group>
                        </FormControl>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={6}>
                        <FormControl>
                          <FormControl.Label>Stripe Price ID</FormControl.Label>
                          <TextInput
                            type="text"
                            readOnly
                            placeholder="Stripe Price Id"
                            name="stripePriceId"
                            // style={{ backgroundColor: 'red' }}
                            onChange={handleChange}
                            value={values.stripePriceId}
                          />
                          <FormControl.Label>Name</FormControl.Label>
                          <TextInput
                            readOnly
                            type="text"
                            placeholder="Name"
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                          />
                          <FormControl.Label>Description</FormControl.Label>
                          <TextInput
                            readOnly
                            type="text"
                            placeholder="Description"
                            name="description"
                            onChange={handleChange}
                            value={values.description}
                          />
                          <FormControl.Label>Price</FormControl.Label>
                          <TextInput
                            readOnly
                            type="text"
                            placeholder="Price"
                            name="price"
                            onChange={handleChange}
                            value={values.price / 100} // Displays the price in decimal
                          />
                          {
                            //* *  Not Needed
                            //* *   <Form.Label>Statement Descriptor</Form.Label>
                            //* *   <Form.Input
                            //* *     readOnly
                            //* *     type="text"
                            //* *     placeholder="Statement Descriptor"
                            //* *      name="statementDescriptor"
                            //* *     onChange={handleChange}
                            //* *      value={values.statementDescriptor}
                            //* *   />
                          }
                          <FormControl.Label>Washn Fee Percentage</FormControl.Label>
                          <TextInput
                            type="number"
                            placeholder="Washn Fee Percentage"
                            name="washnFeePercentage"
                            onChange={handleChange}
                            value={values.washnFeePercentage}
                          />
                        </FormControl>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                  <Card.Footer className="text-right">
                    <Button
                      type="button"
                      outline
                      color="primary"
                      className="mr-2"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="positive">
                      {create ? 'Add Membership' : 'Update Worker'}
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(MembershipCreateUpdate);
