/* eslint-disable camelcase */
import { call, put, takeEvery, getContext } from 'redux-saga/effects';
// import fetch from 'isomorphic-fetch';
import axios from 'axios';
import providerActions, { ProviderTypes } from '../reducers/providers';
import safe from './sagaErrorHandling';
import alertActions from '../reducers/alert';
import formDialogActions from '../reducers/formDialog';
import loadingActions from '../reducers/loading';

function* attachGetProviders() {
  yield takeEvery(ProviderTypes.GET_PROVIDERS, safe(getUserProviders));
}

function* attachGetReports() {
  yield takeEvery(ProviderTypes.GET_REPORTS, safe(getReports));
}

function* attachGetSumReports() {
  yield takeEvery(ProviderTypes.GET_SUM_REPORTS, safe(getSumReports));
}

function* attachGetImages() {
  yield takeEvery(ProviderTypes.GET_IMAGES, safe(getImages));
}

function* attachGetProviderByPk() {
  yield takeEvery(ProviderTypes.GET_PROVIDER_BY_PK, safe(getProviderByPk));
}

function* attachGetCustomersByPk() {
  yield takeEvery(ProviderTypes.GET_CUSTOMERS_BY_PK, safe(getCustomersByPk));
}
/*
function* attachGetCustomerVehiclesByPk() {
  yield takeEvery(ProviderTypes.GET_CUSTOMERVEHICLES_BY_PK, safe(getCustomerVehiclesByPk));
} */

function* attachGetCustomerOrdersByPk() {
  yield takeEvery(ProviderTypes.GET_CUSTOMER_ORDERS_BY_PK, safe(getCustomerOrdersByPk));
}

function* attachInsertProviders() {
  yield takeEvery(ProviderTypes.INSERT_PROVIDER, safe(insertProvider));
}

function* attachUpdateProviderByPk() {
  yield takeEvery(ProviderTypes.UPDATE_PROVIDER_BY_PK, safe(updateProviderByPk));
}

function* attachupdateProviderMobileStoreAvailabilityByPk() {
  yield takeEvery(
    ProviderTypes.UPDATE_PROVIDER_MOBILE_STORE_AVAILABILITY_BY_PK,
    safe(updateProviderMobileStoreAvailabilityByPk),
  );
}

function* attachupdateProviderOpenCloseByPk() {
  yield takeEvery(
    ProviderTypes.UPDATE_PROVIDER_OPEN_CLOSE_BY_PK,
    safe(updateProviderOpenCloseByPk),
  );
}

function* attachDeleteProviderByPk() {
  yield takeEvery(ProviderTypes.DELETE_PROVIDER_BY_PK, safe(deleteProviderByPk));
}

function* attachInsertService() {
  yield takeEvery(ProviderTypes.INSERT_SERVICE, safe(insertService));
}

function* attachUpdateServiceByPk() {
  yield takeEvery(ProviderTypes.UPDATE_SERVICE_BY_PK, safe(updateServiceByPk));
}

function* attachDeleteServiceByPk() {
  yield takeEvery(ProviderTypes.DELETE_SERVICE_BY_PK, safe(deleteServiceByPk));
}

function* attachInsertAddon() {
  yield takeEvery(ProviderTypes.INSERT_ADDON, safe(insertAddon));
}

function* attachUpdateAddonByPk() {
  yield takeEvery(ProviderTypes.UPDATE_ADDON_BY_PK, safe(updateAddonByPk));
}

function* attachDeleteAddonByPk() {
  yield takeEvery(ProviderTypes.DELETE_ADDON_BY_PK, safe(deleteAddonByPk));
}

function* attachInsertServiceImage() {
  yield takeEvery(ProviderTypes.INSERT_SERVICE_IMAGE, safe(insertServiceImage));
}

// function* attachDeleteServiceImageByPk() {
//   yield takeEvery(ProviderTypes.DELETE_SERVICE_IMAGE, safe(deleteServiceImageByPk));
// }

function* attachOpenStripeLink() {
  yield takeEvery(ProviderTypes.OPEN_STRIPE_LINK, safe(openStripeLink));
}

function* attachOpenStripePortalSessionLink() {
  yield takeEvery(ProviderTypes.OPEN_STRIPE_PORTAL_SESSION_LINK, safe(openStripePortalSessionLink));
}

function* attachContinueStripeOnboarding() {
  yield takeEvery(ProviderTypes.CONTINUE_STRIPE_ONBOARDING, safe(continueStripeOnboarding));
}

function* attachDisableWorker() {
  yield takeEvery(ProviderTypes.DISABLE_WORKER, safe(disableWorker));
}

function* attachGetSubscriptions() {
  yield takeEvery(ProviderTypes.GET_SUBSCRIPTIONS, safe(getSubscriptions));
}

function* attachGetProduct() {
  yield takeEvery(ProviderTypes.GET_PRODUCT, safe(getProduct));
}

function* attachInsertMembership() {
  yield takeEvery(ProviderTypes.INSERT_MEMBERSHIP, safe(insertMembership));
}

function* attachUpdateMembershipVisibility() {
  yield takeEvery(ProviderTypes.UPDATE_MEMBERSHIP_VISIBILITY, safe(updateMembershipVisibility));
}

function* attachCancelMembership() {
  yield takeEvery(ProviderTypes.CANCEL_MEMBERSHIP, safe(cancelMembership));
}

function* attachReplaceMembershipVehicle() {
  yield takeEvery(ProviderTypes.REPLACE_MEMBERSHIP_VEHICLE, safe(replaceMembershipVehicle));
}

function* getUserProviders() {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getUserProviders);
    yield put(providerActions.setProviders(data.provider));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getReports() {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getReports);
    yield put(providerActions.setReports(data.order));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getSumReports() {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getSumReports);
    yield put(providerActions.getSumReports(data.order_aggregate));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getImages() {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getImages);
    yield put(providerActions.setImages(data.service_image_link));
    // console.log('======', data.service_image_link);
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* insertProvider({
  form: {
    email,
    phone,
    point_of_contact_name,
    point_of_contact_lastname,
    decision_maker_name,
    decision_maker_lastname,
    city,
    state,
    zip,
    website,
    business_type,
    address,
    name,
    image,
    address_object,
  },
  userId,
}) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  const imagesApi = yield getContext('imagesApi');
  try {
    let key;
    if (image) key = yield call(imagesApi.upload, image, userId);

    const {
      data: { insert_provider_one },
    } = yield call(providersApi.insertProvider, {
      email,
      phone,
      point_of_contact_name,
      point_of_contact_lastname,
      decision_maker_name,
      decision_maker_lastname,
      address,
      city,
      state,
      zip,
      website,
      business_type,
      name,
      userId,
      image: JSON.stringify({ key }),
      location: {
        type: 'Point',
        coordinates: [address_object.position.lng, address_object.position.lat],
      },
    });
    yield put(
      alertActions.displayAlert({
        message: `Provider location '${insert_provider_one.name}' succesfully created.`,
        type: 'success',
      }),
    );
    window.history.back();
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* insertService({
  form: {
    name,
    price,
    deal_price,
    deal_expiry_start,
    deal_expiry,
    description,
    image,
    provider_id,
    duration,
    enable_booking,
  },
  callback,
}) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  // const imagesApi = yield getContext('imagesApi');
  try {
    // let key;
    // if (image) key = yield call(imagesApi.upload, image, provider_id);
    const {
      data: { insert_service_one },
    } = yield call(providersApi.insertService, {
      name,
      price,
      deal_price,
      deal_expiry_start,
      deal_expiry,
      description,
      provider_id,
      created_at: new Date(),
      duration,
      enable_booking,
      image,
      // image: JSON.stringify({ key }),
    });

    // console.log('===========insertService:', insert_service_one);
    yield put(
      alertActions.displayAlert({
        message: `Service '${insert_service_one.name}' succesfully created.`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providerActions.getProviderByPk(provider_id));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* insertAddon({ form: { name, price, image, provider_id }, callback }) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  // const imagesApi = yield getContext('imagesApi');
  try {
    // let key;
    // if (image) key = yield call(imagesApi.upload, image, provider_id);
    const {
      data: { insert_service_addon_one },
    } = yield call(providersApi.insertAddon, {
      name,
      price,
      provider_id,
      created_at: new Date(),
      image,
      // image: JSON.stringify({ key }),
    });
    // console.log('===========insertAddon:', insert_service_addon_one);
    yield put(
      alertActions.displayAlert({
        message: `Addon '${insert_service_addon_one.name}' succesfully created.`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providerActions.getProviderByPk(provider_id));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* insertServiceImage({ form: { name, image, user_id }, callback }) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  const imagesApi = yield getContext('imagesApi');
  try {
    let key;
    if (image) key = yield call(imagesApi.upload, image, user_id);
    // console.log('===============inserted image key:', key);
    const {
      data: { insert_service_image_link_one },
    } = yield call(providersApi.insertServiceImage, {
      name,
      src: JSON.stringify({ key }),
      // user_id,
    });
    // console.log('===============inserted serviceImage:', insert_service_image_link_one);
    yield put(
      alertActions.displayAlert({
        message: `Service Image '${insert_service_image_link_one.title}' succesfully created.`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providerActions.getImages());
  } catch (err) {
    // console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* updateProviderByPk({
  form: {
    email,
    phone,
    point_of_contact_name,
    point_of_contact_lastname,
    decision_maker_name,
    decision_maker_lastname,
    city,
    state,
    zip,
    address,
    website,
    business_type,
    name,
    image,
    id,
    userId,
    oldImageKey,
    userRole,
    verified,
    video,
    location,
    address_object,
    stripe_verified,
    washn_fee_percentage,
    monday_opening_time,
    monday_closing_time,
    tuesday_opening_time,
    tuesday_closing_time,
    wednesday_opening_time,
    wednesday_closing_time,
    thursday_opening_time,
    thursday_closing_time,
    friday_opening_time,
    friday_closing_time,
    saturday_opening_time,
    saturday_closing_time,
    sunday_opening_time,
    sunday_closing_time,
    open_close,
  },
}) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  const adminApi = yield getContext('adminApi');
  const imagesApi = yield getContext('imagesApi');

  try {
    let key;
    if (image) {
      if (oldImageKey) {
        yield call(imagesApi.remove, JSON.parse(oldImageKey).key);
      }
      key = yield call(imagesApi.upload, image, userId);
    }
    const {
      data: { update_provider_by_pk },
    } =
      userRole === 'admin'
        ? yield call(
            adminApi.updateProviderByPk,
            {
              verified,
              video,
              washn_fee_percentage,
              image: key ? JSON.stringify({ key }) : oldImageKey,
              email,
              phone,
              point_of_contact_name,
              point_of_contact_lastname,
              decision_maker_name,
              decision_maker_lastname,
              address,
              city,
              state,
              zip,
              website,
              business_type,
              monday_opening_time,
              monday_closing_time,
              tuesday_opening_time,
              tuesday_closing_time,
              wednesday_opening_time,
              wednesday_closing_time,
              thursday_opening_time,
              thursday_closing_time,
              friday_opening_time,
              friday_closing_time,
              saturday_opening_time,
              saturday_closing_time,
              sunday_opening_time,
              sunday_closing_time,
              open_close,
              name,
              id,
              stripe_verified,
              location: address_object
                ? {
                    type: 'Point',
                    coordinates: [address_object.position.lng, address_object.position.lat],
                  }
                : location,
            },
            id,
          )
        : yield call(providersApi.updateProviderByPk, {
            email,
            phone,
            point_of_contact_name,
            point_of_contact_lastname,
            decision_maker_name,
            decision_maker_lastname,
            address,
            city,
            state,
            zip,
            website,
            business_type,
            name,
            id,
            image: key ? JSON.stringify({ key }) : oldImageKey,
            stripe_verified,
            monday_opening_time,
            monday_closing_time,
            tuesday_opening_time,
            tuesday_closing_time,
            wednesday_opening_time,
            wednesday_closing_time,
            thursday_opening_time,
            thursday_closing_time,
            friday_opening_time,
            friday_closing_time,
            saturday_opening_time,
            saturday_closing_time,
            sunday_opening_time,
            sunday_closing_time,
            open_close,
            location: address_object
              ? {
                  type: 'Point',
                  coordinates: [address_object.position.lng, address_object.position.lat],
                }
              : location,
          });
    yield put(
      alertActions.displayAlert({
        message: `Provider location '${update_provider_by_pk.name}' succesfully updated.`,
        type: 'success',
      }),
    );
    if (userRole === 'provider') window.location.href = `/providers/profile/${id}`;
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getProviders());
    if (userRole === 'admin') {
      yield put(providerActions.getProviderByPk(id));
    }
    if (userRole === 'admin') yield put(formDialogActions.hideForm());
  }
}

function* updateProviderMobileStoreAvailabilityByPk({ form: { id, userRole, mobileStore } }) {
  const providersApi = yield getContext('providersApi');

  try {
    const {
      data: { update_provider_by_pk },
    } = yield call(providersApi.updateProviderMobileStoreAvailabilityByPk, {
      id,
      mobileStore,
    });
    yield put(
      alertActions.displayAlert({
        message: `Provider '${update_provider_by_pk.name}' Mobile Store availability was succesfully updated.`,
        type: 'success',
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    if (userRole === 'admin') {
      yield put(providerActions.getProviderByPk(id));
    }
  }
}

function* updateProviderOpenCloseByPk({ form: { id, userRole, open_close } }) {
  const providersApi = yield getContext('providersApi');

  try {
    const {
      data: { update_provider_by_pk },
    } = yield call(providersApi.updateProviderOpenCloseByPk, {
      id,
      open_close,
    });
    yield put(
      alertActions.displayAlert({
        message: `Provider '${update_provider_by_pk.name}' Open Close was succesfully updated.`,
        type: 'success',
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(
      alertActions.displayAlert({
        message: 'Open Close Oops something went wrong.',
        type: 'error',
      }),
    );
  } finally {
    if (userRole === 'admin') {
      yield put(providerActions.getProviderByPk(id));
    }
  }
}

/**
function* updateServiceByPk({
  form: {
    name,
    price,
    deal_price,
    deal_expiry,
    deal_expiry_start,
    description,
    provider_id,
    image,
    id,
    duration,
    enable_booking,
  },
  callback,
}) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  // const imagesApi = yield getContext('imagesApi');
  try {
    // let key;
    // if (image) {
    //   if (oldImageKey) {
    //     yield call(imagesApi.remove, JSON.parse(oldImageKey).key);
    //   }
    //   key = yield call(imagesApi.upload, image, provider_id);
    // }
    const {
      data: { update_service_by_pk },
    } = yield call(providersApi.updateServiceByPk, {
      name,
      price,
      description,
      deal_expiry: deal_price ? deal_expiry : null,
      deal_expiry_start: deal_price ? deal_expiry_start : null,
      deal_price: deal_price || null,
      modified_at: new Date(),
      id,
      image,
      duration,
      enable_booking,
      // image: image ? JSON.stringify({ image }) : oldImageKey,
    });
    yield put(
      alertActions.displayAlert({
        message: `Service location '${update_service_by_pk.name}' succesfully updated.`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providerActions.getProviderByPk(provider_id));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}
*/

function* updateServiceByPk({
  form: {
    name,
    price,
    deal_price,
    deal_expiry,
    deal_expiry_start,
    description,
    provider_id,
    image,
    id,
    duration,
    enable_booking,
  },
  callback,
}) {
  // Log the payload for debugging purposes
  console.log('Saga payload:', {
    name,
    price,
    deal_price,
    deal_expiry,
    deal_expiry_start,
    description,
    provider_id,
    image,
    id,
    duration,
    enable_booking,
  });

  // Set loading state
  yield put(loadingActions.setLoading(true));

  const providersApi = yield getContext('providersApi');
  // const imagesApi = yield getContext('imagesApi'); // Uncomment if needed

  try {
    // let key;
    // if (image) {
    //   if (oldImageKey) {
    //     yield call(imagesApi.remove, JSON.parse(oldImageKey).key);
    //   }
    //   key = yield call(imagesApi.upload, image, provider_id);
    // }

    // Call the updateServiceByPk API
    const {
      data: { update_service_by_pk },
    } = yield call(providersApi.updateServiceByPk, {
      name,
      price,
      description,
      deal_expiry: deal_price ? deal_expiry : null,
      deal_expiry_start: deal_price ? deal_expiry_start : null,
      deal_price: deal_price || null,
      modified_at: new Date(),
      id,
      image,
      duration,
      enable_booking,
      // image: image ? JSON.stringify({ image }) : oldImageKey,
    });
    console.log('Updated Service:', update_service_by_pk);
    // Display success alert
    yield put(
      alertActions.displayAlert({
        message: `Service location '${update_service_by_pk.name}' successfully updated.`,
        type: 'success',
      }),
    );

    // If a callback function is provided, call it
    if (callback) callback();

    // Dispatch an action to get the updated provider details
    yield put(providerActions.getProviderByPk(provider_id));
  } catch (err) {
    // Log and display error alert
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    // Set loading state to false
    yield put(loadingActions.setLoading(false));
  }
}

function* updateAddonByPk({ form: { name, price, provider_id, image, id }, callback }) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  // const imagesApi = yield getContext('imagesApi');
  try {
    // let key;
    // if (image) {
    //   if (oldImageKey) {
    //     yield call(imagesApi.remove, JSON.parse(oldImageKey).key);
    //   }
    //   key = yield call(imagesApi.upload, image, provider_id);
    // }
    const {
      data: { update_service_addon_by_pk },
    } = yield call(providersApi.updateAddonByPk, {
      name,
      price,
      id,
      image,
      // modified_at: new Date(),
      // image: image ? JSON.stringify({ image }) : oldImageKey,
    });
    yield put(
      alertActions.displayAlert({
        message: `Addon '${update_service_addon_by_pk.name}' succesfully updated.`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providerActions.getProviderByPk(provider_id));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getProviderByPk({ uuid }) {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getProviderByPk, { uuid });
    yield put(providerActions.setSelectedProvider(data.provider_by_pk));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
}

function* deleteProviderByPk({ providerId }) {
  yield put(loadingActions.setLoading(true));
  try {
    const providersApi = yield getContext('providersApi');
    const {
      data: { update_provider_by_pk },
    } = yield call(providersApi.deleteProviderByPk, {
      id: providerId,
      deleted_at: new Date(),
    });
    yield put(
      alertActions.displayAlert({
        message: `Provider location ${update_provider_by_pk.name} successfully deleted`,
        type: 'success',
      }),
    );
  } catch (err) {
    // yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
    // Hiding error when deletting service
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getProviders());
    yield put(formDialogActions.hideForm());
  }
}

function* deleteServiceByPk({ serviceId }) {
  yield put(loadingActions.setLoading(true));
  try {
    const providersApi = yield getContext('providersApi');
    const {
      data: { update_service_by_pk },
    } = yield call(providersApi.deleteServiceByPk, {
      id: serviceId.id,
      deleted_at: new Date(),
    });
    yield put(
      alertActions.displayAlert({
        message: `Service "${update_service_by_pk.name}" successfully deleted`,
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getProviderByPk(serviceId.providerId));
    yield put(formDialogActions.hideForm());
  }
}

function* deleteAddonByPk({ addonId }) {
  yield put(loadingActions.setLoading(true));
  try {
    const providersApi = yield getContext('providersApi');
    const {
      data: { update_service_addon_by_pk },
    } = yield call(providersApi.deleteAddonByPk, {
      id: addonId.id,
      deleted_at: new Date(),
    });
    yield put(
      alertActions.displayAlert({
        message: `Addon "${update_service_addon_by_pk.name}" successfully deleted`,
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getProviderByPk(addonId.providerId));
    yield put(formDialogActions.hideForm());
  }
}

// function* deleteServiceImageByPk({ imageId }) {
// function* deleteServiceImageByPk() {
//   yield put(loadingActions.setLoading(true));
//   try {
//     // const providersApi = yield getContext('providersApi');
//   } catch (err) {
//     yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
//   } finally {
//     yield put(formDialogActions.hideForm());
//   }
// }

function* openStripeLink({ providerId }) {
  yield put(loadingActions.setLoading(true));
  try {
    const providersApi = yield getContext('providersApi');
    const {
      data: {
        connectProviderStripe: { onboarding_url },
      },
    } = yield call(providersApi.openStripeLink, providerId);
    window.open(onboarding_url, '_blank');
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* openStripePortalSessionLink({ providerStripeCustId }) {
  yield put(loadingActions.setLoading(true));
  // const lambdaCreatePortalURI = process.env.REACT_APP_PORTAL_URI; Need to FiX
  // const lambdaCreatePortalURI = 'https://d23r9l19i27w7l.cloudfront.net/portal-link';

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Referrer-Policy': 'origin',
    },
  };

  axios
    .post(
      // lambdaCreatePortalURI,
      {
        customer: providerStripeCustId,
        return_url: 'https://example.com',
      },
      options,
    )
    .then(
      (response) => {
        console.log(response);
        window.open(response.data.sessionUrl, '_blank');
      },
      (error) => {
        console.log(error);
      },
    );
  yield put(loadingActions.setLoading(false));
}

function* continueStripeOnboarding({ stripeAccount }) {
  yield put(loadingActions.setLoading(true));
  try {
    const providersApi = yield getContext('providersApi');
    const {
      data: {
        getStripeLink: { onboarding_url },
      },
    } = yield call(providersApi.getStripeLink, stripeAccount);
    window.open(onboarding_url, '_blank');
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* disableWorker({ email, providerId }) {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    yield call(providersApi.disableWorker, email, providerId);
    yield put(
      alertActions.displayAlert({
        message: `Succesfully deleted worker: ${email}`,
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getProviderByPk(providerId));
    yield put(formDialogActions.hideForm());
  }
}

function* replaceMembershipVehicle({ customerUuid, orderId, newVehicleId }) {
  // console.log(customerUuid, orderId, newVehicleId);
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    yield call(providersApi.replaceMembershipVehicle, orderId, newVehicleId);
    yield put(
      alertActions.displayAlert({
        message: 'Succesfully replaced vehicle',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getCustomerOrdersByPk(customerUuid));
    yield put(formDialogActions.hideForm());
  }
}

function* getCustomersByPk(uuid) {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getCustomersByPk, uuid);
    yield put(providerActions.setCustomers(data.order));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
}
// TO BE USED FOR VEHICLES may need to remove order
/*
function* getCustomerVehiclesByPk(uuid) {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getCustomerVehiclesByPk, uuid);
    console.log(data);
    yield put(providerActions.setVehciles(data));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
} */

function* getCustomerOrdersByPk({ uuid }) {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getCustomerOrdersByPk, { uuid });
    // console.log(data);
    yield put(providerActions.setOrders(data.order));
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
}

function* getSubscriptions() {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getSubscriptions);
    yield put(providerActions.setSubscriptions(data.subscriptions));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getProduct({ productId, providerStripeAccount }) {
  try {
    const providersApi = yield getContext('providersApi');
    const { data } = yield call(providersApi.getProduct, productId, providerStripeAccount);
    yield put(providerActions.setStripeProductInfo(data.getProduct));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* insertMembership({
  form: { productId, name, description, price, providerId, stripePriceId, washnFeePercentage },
  callback,
}) {
  yield put(loadingActions.setLoading(true));
  const providersApi = yield getContext('providersApi');
  try {
    const { data } = yield call(providersApi.insertMembership, {
      name,
      price,
      description,
      productId,
      providerId,
      stripePriceId,
      washnFeePercentage,
    });
    yield put(
      alertActions.displayAlert({
        message: `Membership '${data.insert_memberships_one.name}' succesfully created.`,
        type: 'success',
      }),
    );
    if (callback) callback();
  } catch (err) {
    console.log(err);
    yield put(alertActions.displayAlert({ message: 'Oops something went wrong.', type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* updateMembershipVisibility({ id, visible }) {
  try {
    const providersApi = yield getContext('providersApi');
    yield call(providersApi.updateMembershipVisibility, { id, visible });
    yield put(
      alertActions.displayAlert({
        message: 'Membership visibility updated',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* cancelMembership({ orderId, customerUuid }) {
  try {
    yield put(loadingActions.setLoading(true));
    const providersApi = yield getContext('providersApi');
    yield call(providersApi.cancelMembership, orderId);
    yield put(
      alertActions.displayAlert({
        message: `Membership of order ${orderId} was succesfully cancelled`,
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
    yield put(providerActions.getCustomerOrdersByPk(customerUuid));
  }
}

export default [
  attachGetProviders,
  attachGetReports,
  attachGetSumReports,
  attachInsertProviders,
  attachGetProviderByPk,
  attachUpdateProviderByPk,
  attachupdateProviderMobileStoreAvailabilityByPk,
  attachupdateProviderOpenCloseByPk,
  attachDeleteProviderByPk,
  attachInsertService,
  attachUpdateServiceByPk,
  attachDeleteServiceByPk,
  attachInsertAddon,
  attachUpdateAddonByPk,
  attachDeleteAddonByPk,
  attachOpenStripeLink,
  attachOpenStripePortalSessionLink,
  attachContinueStripeOnboarding,
  attachDisableWorker,
  attachGetCustomersByPk,
  // attachGetCustomerVehiclesByPk,
  attachGetCustomerOrdersByPk,
  attachGetSubscriptions,
  attachGetProduct,
  attachInsertMembership,
  attachUpdateMembershipVisibility,
  attachCancelMembership,
  attachReplaceMembershipVehicle,
  attachGetImages,
  attachInsertServiceImage,
  // attachDeleteServiceImageByPk,
];
