/* eslint-disable object-shorthand */
import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  userProviders: [],
  userReports: [],
  myReports: [],
  serviceImages: [],
  userImages: [],
  selectedProvider: {},
  providerCustomers: [],
  customerOrders: [],
  providerNylasId: null,
};

const { Types, Creators } = createActions({
  insertProvider: ['form', 'userId'],
  updateProviderByPk: ['form'],
  updateProviderMobileStoreAvailabilityByPk: ['form'],
  updateProviderOpenCloseByPk: ['form'],
  deleteProviderByPk: ['providerId'],
  getProviders: null,
  setProviders: ['providers'],
  getReports: null,
  setReports: ['reports'],
  getSumReports: null,
  setSumReports: ['reports'],
  getImages: [],
  setImages: ['images'],
  getProviderByPk: ['uuid'],
  setSelectedProvider: ['provider'],
  setProviderNylasId: ['providerNylasId'],
  cleanSelectedProvider: null,
  insertService: ['form', 'callback'],
  updateServiceByPk: ['form', 'callback'],
  deleteServiceByPk: ['serviceId'],
  insertAddon: ['form', 'callback'],
  updateAddonByPk: ['form', 'callback'],
  deleteAddonByPk: ['addonId'],
  openStripeLink: ['providerId'],
  openStripePortalSessionLink: ['providerStripeCustId'],
  continueStripeOnboarding: ['stripeAccount'],
  disableWorker: ['email', 'providerId'],
  getCustomersByPk: ['uuid'],
  setCustomers: ['customers'],
  getCustomerOrdersByPk: ['uuid'],
  setOrders: ['orders'],
  getSubscriptions: null,
  setSubscriptions: ['subscriptions'],
  getProduct: ['productId', 'providerStripeAccount'],
  setStripeProductInfo: ['productInfo'],
  insertMembership: ['form', 'callback'],
  updateMembershipVisibility: ['id', 'visible'],
  cancelMembership: ['orderId', 'customerUuid'],
  replaceMembershipVehicle: ['customerUuid', 'orderId', 'newVehicleId'],
  insertServiceImage: ['form', 'callback'],
  // deleteServiceImageByPk: ['imageId'],
});

const setProviders = (state, { providers }) => ({ ...state, userProviders: providers });
const setReports = (state, { reports }) => ({ ...state, userReports: reports });
const setSumReports = (state, { willreports }) => ({ ...state, myReports: willreports });
const setCustomers = (state, { customers }) => ({ ...state, providerCustomers: customers });
const setOrders = (state, { orders }) => ({ ...state, customerOrders: orders });
const setImages = (state, { images }) => ({ ...state, userImages: images });
const setSelectedProvider = (state, { provider }) => ({ ...state, selectedProvider: provider });
const setProviderNylasId = (state, { providerNylasId }) => ({
  ...state,
  providerNylasId: providerNylasId,
});
const cleanSelectedProvider = (state) => ({ ...state, selectedProvider: {} });
const setSubscriptions = (state, { subscriptions }) => ({
  ...state,
  stripeSubscriptions: subscriptions,
});
const setStripeProductInfo = (state, { productInfo }) => ({
  ...state,
  stripeProductInfo: productInfo,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROVIDERS]: setProviders,
  [Types.SET_REPORTS]: setReports,
  [Types.SET_SUM_REPORTS]: setSumReports,
  [Types.SET_IMAGES]: setImages,
  [Types.SET_SELECTED_PROVIDER]: setSelectedProvider,
  [Types.SET_PROVIDER_NYLAS_ID]: setProviderNylasId,
  [Types.CLEAN_SELECTED_PROVIDER]: cleanSelectedProvider,
  [Types.SET_CUSTOMERS]: setCustomers,
  [Types.SET_ORDERS]: setOrders,
  [Types.SET_SUBSCRIPTIONS]: setSubscriptions,
  [Types.SET_STRIPE_PRODUCT_INFO]: setStripeProductInfo,
});

export const ProviderTypes = Types;
export default Creators;
