/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars, prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { Table, Text, Form } from 'tabler-react';
import { TextLink } from '@contentful/f36-text-link';
import { Card } from '@contentful/f36-card';
import { Subheading } from '@contentful/f36-typography';
import { TablePagination } from '@material-ui/core/';
// import formActions from '../../../../redux/reducers/formDialog';

export default function CustomerTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // const dispatch = useDispatch();
  // const history = useHistory();

  const providers = useSelector((state) => state.providers.providerCustomers);
  // const { role } = useSelector((state) => state.auth.user);
  // console.log('BOOM', providers.length);

  const [search, setSearch] = useState('');
  // const [sortedProviders, setSortedProviders] = useState(providers);

  // const { position: { lat, lng } } = JSON.parse(providers[0].user.profile.verified_location);
  // console.log(lat, lng);
  // const setCentralLoc = ({ latitude: lat, longitude: lng });
  // console.log(setCentralLoc);

  useEffect(() => {
    if (typeof search !== 'string' || search.length === 0) {
      // setSortedProviders(providers);
    }

    // const searchLower = search.toLowerCase();
    // setSortedProviders();
  }, [providers, search]);

  if (!providers) return null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <Card
      className="pt-3"
      title={
        <>
          <Subheading marginTop="spacingS" marginBottom="spacingM" marginLeft="spacingS">
            Customers
          </Subheading>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Input
              className="mb-2 mt-2"
              icon="search"
              placeholder="Search by email or name..."
              position="append"
              onChange={handleInputChange}
            />
          </div>
        </>
      }
    >
      {providers.length === 0 ? (
        <Text center muted>
          This location has no customers
        </Text>
      ) : (
        <>
          <Table
            highlightRowOnHover
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: 'Customer Name' },
              // { contect: 'Cordinates' },
              { content: 'Email' },
              { content: 'Phone' },
              { content: 'Address' },
              { content: 'City' },
              { content: 'State' },
              { content: 'Zipcode' },
            ]}
            bodyItems={providers.map(({ user, details }) => ({
              key: user.id,
              item: [
                {
                  content: (
                    <TextLink variant="primary" href={`/providers/customer/${user.id}`}>
                      {user.profile.name} {''} {user.profile.lastname}
                    </TextLink>
                  ),
                },
                // { content: setCentralLoc },
                { content: user.email },
                { content: user.profile.phone_number },
                {
                  content: (
                    <span title={user.profile.address}>
                      {user.profile.address.length > 15
                        ? `${user.profile.address.substring(0, 15)}...`
                        : user.profile.address}
                    </span>
                  ),
                },
                { content: user.profile.city },
                { content: user.profile.state },
                { content: user.profile.zip },
              ],
            }))}
          />
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={providers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
          />
        </>
      )}
    </Card>
  );
}
