import React, { useState, useEffect } from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import { Form, FormControl, TextInput } from '@contentful/f36-forms';
import { Button } from '@contentful/f36-button';
import { Modal } from '@contentful/f36-modal';

export default function ForgotPasswordDialog({ handleClose, setShown, isShown, handleSubmit }) {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    if (email.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setErrors({ message: 'Please enter a valid email' });
    } else setErrors(null);
  }, [email]);

  return (
    <Modal size="medium" onClose={() => setShown(false)} isShown={isShown}>
      <Modal.Header title="Reset Your Password" onClose={handleClose} />
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <FormControl>
            <FormControl.Label isRequired>Email</FormControl.Label>
            <TextInput
              autoFocus
              type="email"
              name="email"
              placeholder="your-email@domain.com"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              helperText={errors ? errors?.message : null}
              error={!!errors}
              fullwidth
            />
          </FormControl>
        </Form>
      </Modal.Content>
      <Modal.Controls>
        <Button size="small" variant="negative" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="primary"
          isDisabled={email.length === 0 || errors?.message.length > 0}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(email);
          }}
        >
          Submit
        </Button>
      </Modal.Controls>
    </Modal>
  );
}
