/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable implicit-arrow-linebreak, no-unused-vars, max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { Table, Text, Form, Dropdown } from 'tabler-react';

import { Card } from '@contentful/f36-card';
import { Subheading } from '@contentful/f36-typography';
import { TextLink } from '@contentful/f36-text-link';
import { TextInput, FormControl } from '@contentful/f36-forms';
import { TablePagination } from '@material-ui/core/';
// import formActions from '../../../../redux/reducers/formDialog';
import moment from 'moment';
import providersActions from '../../../../redux/reducers/providers';
import Stat from '../Products';

export default function ReportTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [reportsCustom, setReportsCustom] = useState([]);

  const dispatch = useDispatch();
  // const history = useHistory();

  const providers = useSelector((state) => state.providers.userProviders);
  // console.log('HERE', providers[0].orders_aggregate.aggregate.count);
  // console.log('SUM', providers[0].orders_aggregate.aggregate.sum);
  const reports = useSelector((state) => state.providers.userReports);
  // const willreports = useSelector((state) => state.providers.myReports);
  // const { role } = useSelector((state) => state.auth.user);

  // console.log('YAHSO');
  const [search, setSearch] = useState('');
  const [sortedReports, setSortedReports] = useState(reportsCustom);

  // console.log('SELECTED', reports);

  useEffect(() => {
    dispatch(providersActions.getProviders());
  }, [providers]);

  useEffect(() => {
    dispatch(providersActions.getReports());
    const data = reports.map((item) => ({
      id: item.id,
      user_id: item.user.id,
      name: item.user.profile.name,
      lastname: item.user.profile.lastname,
      email: item.user.email,
      order_number: item.number,
      service_name: item.details[0].service?.name,
      addon_name: item.details[0].addon?.name,
      membership_name: item.details[0].membership?.name,
      order_code: item.washn_code,
      washn_order_status: item.status,
      // orderwashn_code: item.details[0].order.washn_code,
      // service_name: item.details[0].service.name,  //// Does not work
      // addon_name: item.details[1].addon.name,   //// Does not work
      checkin_at: item.checkin_at,
      order_date: item.created_at,
      order_status: item.charge_status,
      total: item.total,
    }));
    setReportsCustom(data);
    // console.log('THISSS', reports);
    // console.log('==========ReportsCustom:', data);
  }, [reports]);

  // console.log('IDDDDDD', [reports]);

  useEffect(() => {
    if (typeof search !== 'string' || search.length === 0) {
      setSortedReports(reportsCustom);
    }

    const searchLower = search.toLowerCase();
    setSortedReports(
      reportsCustom.filter((report) => {
        if (report.name.toLowerCase().includes(searchLower)) {
          return true;
        }

        if (report.email.toLowerCase().includes(searchLower)) {
          return true;
        }
        if (report.washn_order_status.toLowerCase().includes(searchLower)) {
          return true;
        }

        return false;
      }),
    );
    // console.log('==================sortedreports:', sortedReports);
  }, [reportsCustom, search]);

  if (!reports) return null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <Card
      className="pt-3"
      title={
        <>
          {/* <span className="mt-3">Reports</span> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl className="col-12" marginTop="spacingXl" marginBottom="spacingM">
              <TextInput.Group spacing="spacingS">
                <TextInput
                  md={4}
                  // cclassName="mb-2 mt-2"
                  // cicon="search"
                  placeholder="Name or email"
                  // cposition="append"
                  onChange={handleInputChange}
                />
                <Dropdown
                  type="button"
                  // style={{ backgroundColor: 'red' }}
                  toggle
                  triggerContent="Filter"
                  color="primary"
                  icon="filter"
                  items={[
                    <Dropdown.Item
                      onClick={() =>
                        setSortedReports(reportsCustom.filter((item) => !!item.order_status))
                      }
                    >
                      Successed
                    </Dropdown.Item>,
                    <Dropdown.Item
                      onClick={() =>
                        setSortedReports(reportsCustom.filter((item) => !item.order_status))
                      }
                    >
                      Not Successed
                    </Dropdown.Item>,
                    <Dropdown.ItemDivider />,
                    <Dropdown.Item onClick={() => setSortedReports(reportsCustom)}>
                      Remove Filters
                    </Dropdown.Item>,
                  ]}
                />
              </TextInput.Group>
            </FormControl>
          </div>
        </>
      }
    >
      {reports.length === 0 ? (
        <Text center muted>
          You currently have no reports.
        </Text>
      ) : (
        <>
          <Table
            style={{ fontSize: 12 }}
            highlightRowOnHover
            responsive
            className="card-table text-nowrap"
            headerItems={[
              { content: 'Customer Name' },
              { content: 'Email' },
              { content: '#' },
              { content: 'Code' },
              { content: 'Order Status' },
              { content: 'Checked At' },
              { content: 'Service' },
              { content: 'Addon' },
              { content: 'Membership' },
              { content: 'Order Date' },
              { content: 'Payment Status' },
              { content: 'Total' },
            ]}
            bodyItems={sortedReports
              .sort((a, b) => {
                if (a.verified === true && b.verified === false) return 1;
                if (a.verified === false && b.verified === true) return -1;
                return 0;
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                ({
                  id,
                  user_id,
                  name,
                  lastname,
                  email,
                  order_number,
                  order_code,
                  washn_order_status,
                  checkin_at,
                  service_name,
                  addon_name,
                  membership_name,
                  order_date,
                  order_status,
                  total,
                }) => ({
                  key: id,
                  item: [
                    {
                      content: (
                        <TextLink variant="primary" href={`/providers/customer/${user_id}`}>
                          {name} {lastname}
                        </TextLink>
                      ),
                    },
                    { content: email },
                    { content: order_number },
                    { content: order_code },
                    { content: washn_order_status },
                    {
                      content: checkin_at
                        ? moment(new Date(checkin_at).valueOf()).format('MM/DD/yyyy h:mm A')
                        : '',
                    },
                    // { content: moment(new Date(checkin_at).valueOf()).format('MM/DD/yyyy hh:mm') },
                    { content: service_name },
                    { content: addon_name },
                    { content: membership_name },
                    { content: moment(new Date(order_date).valueOf()).format('MMM Do YY h:mm A') },
                    { content: order_status },
                    { content: `$${total.toFixed(2)}` },
                  ],
                }),
              )}
          />
          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component="div"
            count={sortedReports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
          />
        </>
      )}
    </Card>
  );
}
