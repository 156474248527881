import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Icon } from 'tabler-react';
import { Formik } from 'formik';
import authActions from '../../redux/reducers/auth';
// import * as styles from '../../styles/not-authenticated/notauth.module.css';
import styles from '../../styles/not-authenticated/notauth.module.css';
import logo from '../../assets/washn logo.png';
import StandaloneFormPage from '../../helpers/StandaloneFormPage';

function Signup() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  return (
    <StandaloneFormPage image={logo}>
      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.password) errors.password = 'Required';

            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(
              authActions.signUp(values.email, values.password, () => history.push('/login')),
            );
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form.Group
              label="Welcome to Washn! Please join us by filling this form"
              className={styles.inputsContainer}
            >
              <Form.Input
                type="email"
                placeholder="Email"
                value={values.email}
                tick={!errors.email && values.email}
                onChange={({ target: { value } }) => {
                  setFieldValue('email', value.toLowerCase(), true);
                }}
                name="email"
                error={errors.email ? errors.email : null}
              />
              <Form.InputGroup>
                <Form.Input
                  className="mt-3"
                  placeholder="Password"
                  tick={!errors.password && values.password}
                  value={values.password}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  error={errors.password ? errors.password : null}
                />
                <Form.InputGroupAppend>
                  <button
                    className="mt-3"
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    style={{
                      width: '30px',
                      border: '1px solid rgba(0, 40, 100, 0.12)',
                      borderLeft: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'rgba(0, 40, 100, 0.5)',
                    }}
                  >
                    <Icon name={showPassword ? 'eye-off' : 'eye'} />
                  </button>
                </Form.InputGroupAppend>
              </Form.InputGroup>
              <Button
                className="mt-3"
                block
                RootComponent="button"
                color="primary"
                onClick={handleSubmit}
                type="submit"
              >
                Sign Up
              </Button>
              <Button
                block
                RootComponent="button"
                outline
                color="primary"
                onClick={() => {
                  window.location.href = '/';
                }}
                type="button"
              >
                Go Back
              </Button>
            </Form.Group>
          )}
        </Formik>
      </div>
    </StandaloneFormPage>
  );
}

export default withRouter(Signup);
