import React, { useState } from 'react';

import { Table, Text } from 'tabler-react';
import { Card } from '@contentful/f36-card';
import { TablePagination } from '@material-ui/core/';

export default function DenseTable({ title, columns, data }) {
  if (!data || !columns) return null;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card title={title}>
      {data.length === 0 ? (
        <Text muted className="m-5">
          This table has no info for this provider.
        </Text>
      ) : (
        <>
          <Table
            responsive
            highlightRowOnHover
            className="card-table table-vcenter text-nowrap "
            headerItems={columns.map((name) => ({ content: name }))}
            bodyItems={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          />
          <TablePagination
            className="card-table table-vcenter text-nowrap "
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
          />
        </>
      )}
    </Card>
  );
}
