import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Container, Form, Grid } from 'tabler-react';
import { Button, ButtonGroup } from '@contentful/f36-button';
import { FormControl, TextInput } from '@contentful/f36-forms';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import authActions from '../../../redux/reducers/auth';

function ProviderUserData() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // eslint-disable-next-line camelcase
  const { lastname, phone, name, id: profileId } = useSelector((state) => state.profile);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            initialValues={{
              email: user.email,
              firstname: name || '',
              lastname: lastname || '',
              phone: phone || '',
            }}
            validate={(values) => {
              const errors = {};
              if (values.firstname.length === 0) errors.firstname = 'Required';
              if (values.lastname.length === 0) errors.lastname = 'Required';
              if (values.phone.length === 0) errors.phone = 'Required';

              return errors;
            }}
            validateOnChange
            onSubmit={(values) => {
              if (!profileId) {
                const submitValues = { ...values };
                delete submitValues.email;

                dispatch(authActions.createProfile({ ...submitValues, userId: user.id }));
              } else {
                const submitValues = { ...values };
                delete submitValues.email;

                dispatch(authActions.updateProfile({ ...submitValues, id: profileId }));
              }

              // if (create) {
              //   return dispatch(
              //     providersActions.insertService(
              //       {
              //         ...values,
              //         image,
              //         provider_id: provider.id,
              //       },
              //       history.push(`/providers/profile/${provider.id}`),
              //     ),
              //   );
              // }
              // return dispatch(
              //   providersActions.updateServiceByPk(
              //     {
              //       ...values,
              //       image,
              //       id: toEdit.id,
              //       provider_id: provider.id,
              //       oldImageKey: toEdit.image,
              //     },
              //     () => history.push(`/providers/profile/${provider.id}`),
              //   ),
              // );
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Form className="card" onSubmit={handleSubmit}>
                <Card.Header>
                  <Card.Title>Add or Update Your Profile</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col md={4} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Email</FormControl.Label>
                        <Form.Input disabled autoComplete="off" type="text" value={values.email} />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={4} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Phone*</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          type="text"
                          placeholder="Phone"
                          onChange={handleChange}
                          name="phone"
                          value={values.phone}
                          error={!!errors.phone}
                          tick={!errors.phone && values.phone.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={4} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Firstname*</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          type="text"
                          placeholder="Firstname"
                          name="firstname"
                          onChange={handleChange}
                          value={values.firstname}
                          error={!!errors.firstname}
                          tick={!errors.firstname && values.firstname.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={4} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Lastname*</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          type="text"
                          placeholder="Lastname"
                          onChange={handleChange}
                          name="lastname"
                          value={values.lastname}
                          error={!!errors.lastname}
                          tick={!errors.lastname && values.lastname.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer className="text-right">
                  <ButtonGroup variant="spaced" spacing="spacingM">
                    <Button
                      // type="button"
                      // outline
                      variant="secondary"
                      className="mr-2"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="positive"
                      disabled={Object.keys(errors).length > 0}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ProviderUserData);
