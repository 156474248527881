/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase, no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label , max-len */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Text, Dropdown } from 'tabler-react';

import { Badge } from '@contentful/f36-badge';
import { FormControl, TextInput } from '@contentful/f36-forms';
import { IconButton } from '@contentful/f36-button';
import { Tooltip } from '@contentful/f36-tooltip';
import { TextLink } from '@contentful/f36-text-link';
import { Card } from '@contentful/f36-card';

import {
  UsersTrimmedIcon,
  EditIcon,
  DeleteIcon,
  PreviewOffIcon,
  PreviewIcon,
  CycleIcon,
  FilterIcon,
  ReferencesIcon,
  DoubleArrowIcon,
} from '@contentful/f36-icons';
import { TablePagination } from '@material-ui/core/';
import formActions from '../../../../redux/reducers/formDialog';
import providersActions from '../../../../redux/reducers/providers';

export default function ProvidersTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();
  const history = useHistory();

  const providers = useSelector((state) => state.providers.userProviders);
  const { role } = useSelector((state) => state.auth.user);
  // console.log('HERE', providers[0].orders_aggregate.aggregate.count);
  console.log(providers);

  const [search, setSearch] = useState('');
  const [sortedProviders, setSortedProviders] = useState(providers);

  const handleDelete = (providerId) => {
    dispatch(providersActions.deleteProviderByPk(providerId));
  };

  useEffect(() => {
    dispatch(providersActions.getProviders());
  }, [providers]);

  useEffect(() => {
    if (typeof search !== 'string' || search.length === 0) {
      setSortedProviders(providers);
    }

    const searchLower = search.toLowerCase();
    setSortedProviders(
      providers.filter((provider) => {
        if (provider.name.toLowerCase().includes(searchLower)) {
          return true;
        }

        if (provider.email.toLowerCase().includes(searchLower)) {
          return true;
        }

        return false;
      }),
    );
  }, [providers, search]);

  if (!providers) return null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleProviderVerification = (id, verified) => {
    dispatch(
      providersActions.updateProviderByPk({
        verified: !verified,
        id,
        userRole: role,
      }),
    );
  };

  const generateStripeButtons = ({ stripe_account, stripe_verified, id }) => {
    if (stripe_account) {
      if (!stripe_verified && role === 'provider') {
        return (
          <Tooltip placement="auto" content="Stripe Connect has an error.">
            <Badge variant="warning">Needs Attention</Badge>
          </Tooltip>
        );
      }
      return (
        <>
          <Badge variant={stripe_verified ? 'primary-filled' : 'warning'} className="ml-1">
            {stripe_verified ? 'Verified' : 'Not Verified'}
          </Badge>
        </>
      );
    }
    return role !== 'provider' ? (
      <Badge
        variant="negative"
        // type="button"
        // onClick={() => dispatch(providersActions.openStripeLink(id))}
        // style={{ color: '#1D4C6F' }}
      >
        Not Configured
      </Badge>
    ) : (
      <Tooltip placement="auto" content="">
        <Badge variant="negative">Not Started</Badge>
      </Tooltip>
    );
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Card
      className="pt-3"
      title={
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl className="col-12" marginTop="spacingXl" marginBottom="none">
              <TextInput.Group spacing="spacingS">
                <TextInput
                  // icon="search"
                  placeholder="Provider Name or Email"
                  // position="append"
                  onChange={handleInputChange}
                />
                <Dropdown
                  type="button"
                  // style={{ backgroundColor: 'red' }}
                  toggle
                  triggerContent="Filter"
                  color="primary"
                  outline
                  icon="filter"
                  items={[
                    <Dropdown.Item
                      onClick={() =>
                        setSortedProviders(providers.filter((item) => !!item.verified))
                      }
                    >
                      Account Enabled
                    </Dropdown.Item>,
                    <Dropdown.Item
                      onClick={() => setSortedProviders(providers.filter((item) => !item.verified))}
                    >
                      Account Disabled
                    </Dropdown.Item>,
                    <Dropdown.Item
                      onClick={() =>
                        setSortedProviders(providers.filter((item) => !!item.mobile_store))
                      }
                    >
                      Mobile Store Active
                    </Dropdown.Item>,
                    <Dropdown.Item
                      onClick={() =>
                        setSortedProviders(providers.filter((item) => !item.mobile_store))
                      }
                    >
                      Mobile Store Maintenance
                    </Dropdown.Item>,
                    <Dropdown.Item onClick={() => setSortedProviders(providers)}>
                      Clear Filter
                    </Dropdown.Item>,
                  ]}
                />
              </TextInput.Group>
            </FormControl>
          </div>
        </>
      }
    >
      {providers.length === 0 ? (
        <Text center muted>
          You currently have no locations assigned.
        </Text>
      ) : (
        <>
          <Table
            style={{ fontSize: 12 }}
            highlightRowOnHover
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: 'ID' },
              { content: 'Name' },
              { content: 'Email' },
              { content: 'Phone' },
              { content: 'Status' },
              { content: 'Stripe Connect' },
              { content: 'Mobile Store' },
              { content: 'Customers' },
              { content: 'Delete' },
            ]}
            bodyItems={sortedProviders
              .sort((a, b) => {
                if (a.verified === true && b.verified === false) return 1;
                if (a.verified === false && b.verified === true) return -1;
                return 0;
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                ({
                  id,
                  location_id,
                  name,
                  email,
                  phone,
                  // address,
                  stripe_account,
                  mobile_store,
                  stripe_verified,
                  verified,
                }) => ({
                  key: id,
                  item: [
                    {
                      // Site Number
                      content: (
                        <TextLink variant="primary" href={`/providers/profile/${id}`}>
                          {location_id}
                        </TextLink>
                      ),
                    },
                    {
                      // Location Name
                      content: (
                        <TextLink variant="primary" href={`/providers/profile/${id}`}>
                          {name}
                        </TextLink>
                      ),
                    },
                    {
                      // Location Email
                      content: email,
                    },
                    {
                      // Location Phone
                      content: phone,
                    },
                    {
                      // Location Washn Account Status
                      content: (
                        <Badge variant={verified ? 'positive' : 'negative'} className="ml-1">
                          {verified ? 'Enabled' : 'Disabled'}
                        </Badge>
                      ),
                    },
                    {
                      // Location Stripe Account Status
                      content: generateStripeButtons({ stripe_account, stripe_verified, id }),
                    },
                    {
                      content: (
                        <Badge
                          variant={mobile_store ? 'primary-filled' : 'warning'}
                          className="mr-1"
                        >
                          {mobile_store ? 'Mobile Store Active' : 'Maintenance Mode'}
                        </Badge>
                      ),
                    },
                    {
                      // Location Customers
                      content: (
                        <Tooltip placement="auto" content="View Customers">
                          <IconButton
                            icon={<UsersTrimmedIcon variant="primary" />}
                            size="medium"
                            as="a"
                            href={`/providers/customers/${id}`}
                            // variant="primary"
                          />
                        </Tooltip>
                      ),
                    },
                    /*    {
                      content: (
                        <span title={address}>
                          {address.length > 15 ? `${address.substring(0, 15)}...` : address}
                        </span>
                      ),
                    }, */
                    {
                      // alignContent: 'right',
                      content:
                        // role !== 'provider' ? (
                        // Enable or Disable location
                        // role === 'admin'
                        role !== 'provider' ? (
                          <>
                            {verified ? (
                              <Tooltip placement="auto" content="Disable">
                                <IconButton
                                  icon={<PreviewIcon variant="positive" size="small" />}
                                  onClick={() =>
                                    dispatch(
                                      formActions.displayForm({
                                        title: 'Confirmation',
                                        description: `Are you sure you want to disable provider location "${name}"?.`,
                                        handleSubmit: () =>
                                          handleProviderVerification(id, verified),
                                        closeButtonText: 'Cancel',
                                        submitButtonText: 'Confirm',
                                      }),
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip placement="auto" content="Enable this location">
                                <IconButton
                                  icon={<PreviewOffIcon variant="negative" size="small" />}
                                  onClick={() =>
                                    dispatch(
                                      formActions.displayForm({
                                        title: 'Confirmation',
                                        description: `Are you sure you want to verify provider location "${name}"?.`,
                                        handleSubmit: () =>
                                          handleProviderVerification(id, verified),
                                        closeButtonText: 'Cancel',
                                        submitButtonText: 'Confirm',
                                      }),
                                    )
                                  }
                                />
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          // Delete Location
                          <>
                            <Tooltip placement="auto" content="Delete this location">
                              <IconButton
                                icon={<DeleteIcon variant="negative" size="small" />}
                                onClick={() =>
                                  dispatch(
                                    formActions.displayForm({
                                      title: 'Confirm delete',
                                      description: `Are you sure you want to delete "${name}" location?`,
                                      handleSubmit: () => handleDelete(id),
                                      closeButtonText: 'Cancel',
                                      submitButtonText: 'Confirm',
                                    }),
                                  )
                                }
                              />
                            </Tooltip>
                          </>
                        ),
                    },
                    {
                      /**
                    {
                      content: (
                        <IconButton
                          icon={<DeleteIcon variant="negative" size="small" />}
                          onClick={() =>
                            dispatch(
                              formActions.displayForm({
                                title: 'Confirm delete',
                                description: `Are you sure you want to delete provider location "${name}"?.`,
                                handleSubmit: () => handleDelete(id),
                                closeButtonText: 'Cancel',
                                submitButtonText: 'Confirm',
                              }),
                            )
                          }
                        />
                      ),
                    },
                  */
                    },
                  ],
                }),
              )}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedProviders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
          />
        </>
      )}
    </Card>
  );
}
