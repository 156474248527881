/* eslint-disable max-len, no-unused-vars, no-shadow, camelcase */
import React from 'react';
import { Button } from 'tabler-react';
import { useSelector } from 'react-redux';

const NylasAuthButton = () => {
  const providerNylasId = useSelector((state) => state.providers.selectedProvider.id);
  const providerUserEmail = useSelector((state) => state.providers.selectedProvider.email);

  console.log('Provider Nylas Id:', providerNylasId);
  console.log('Email:', providerUserEmail);
  const nylasAuthURL = 'https://api.nylas.com/oauth/authorize';
  const clientId = '9vf88e2q1tal0i2xqqgc9l66j';
  const scopes = 'calendar';
  // const redirectUri = 'http://localhost:3000/nylas-callback'; // Update to match the callback route
  const redirectUri = 'https://admin.development.washn.app/nylas-callback'; // Update to match the callback route
  const login_hint = 'hughlonf@gmail.com';

  const handleNylasAuthClick = () => {
    // Store the providerNylasId in localStorage
    localStorage.setItem('providerNylasId', providerNylasId);

    const authURL = `${nylasAuthURL}?client_id=${clientId}&response_type=code&scopes=${scopes}&redirect_uri=${redirectUri}&login_hint=${login_hint}`;
    // Open the authorization URL in a new tab/window
    window.open(authURL, '_blank');
  };
  return (
    <Button type="button" color="primary" outline icon="calendar" onClick={handleNylasAuthClick}>
      Setup
    </Button>
  );
};

export default NylasAuthButton;
