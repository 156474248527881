/* eslint-disable no-unused-vars, prettier/prettier, jsx-quotes */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Text,
  Card,
  Icon,
  ContactCard,
  StampCard,
  // Badge,
  Form,
} from 'tabler-react';
import { Subheading } from '@contentful/f36-typography';
import { ButtonGroup } from '@contentful/f36-button';
import { Stack } from '@contentful/f36-core';
import { Tooltip } from '@contentful/f36-tooltip';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import authActions from '../../../redux/reducers/auth';

function CalendarSetup() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // eslint-disable-next-line camelcase
  const { lastname, phone, name, id: profileId } = useSelector((state) => state.profile);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Calendar Setup</Card.Title>
            </Card.Header>
          </Card>
          <Grid.Row cards deck>
            <Grid.Col md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Subheading marginBottom="none">Stripe</Subheading>
                  </Card.Title>
                  <Card.Options>
                    <Stack flexDirection="row" spacing="spacingS">
                      <Tooltip placement="top" id="tooltip-1" content="This feature is disabled.">
                        <Icon prefix="fe" name="info" />
                      </Tooltip>
                    </Stack>
                  </Card.Options>
                </Card.Header>
                <Card.Footer flexWrap="wrap" className="text-left" />
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(CalendarSetup);
