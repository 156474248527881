/* eslint-disable camelcase, no-shadow, no-unused-vars, object-shorthand, max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'tabler-react';
import axios from 'axios';
import { Radio, FormControl } from '@contentful/f36-forms';
import { Paragraph } from '@contentful/f36-typography';
import SiteWrapper from '../../../../wrappers/SiteWrapper';

const NylasAuthCallback = () => {
  const reduxState = useSelector((state) => state);
  console.log('Redux Store State:', reduxState);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null); // State for selected calendar
  const [calendars, setCalendars] = useState([]);
  const [userAccessToken, setUserAccessToken] = useState('');
  const [myData, setMyData] = useState(null); // State to store mydata
  const [nylas_calendar, setNylasCelendar] = useState(null); // State to store nylas_calendar
  const [nylas_selected_calendar, setNylasSelectedCalendar] = useState(null); // State to store the selected nylas_calendar

  const code = document.location.search.substring(1).split('&')[0].split('=')[1];
  let encodedCode;
  const providerNylasId = localStorage.getItem('providerNylasId');
  const userId = useSelector((state) => state.auth.user.id);

  console.log('Provider Nylas Id from Redux:', providerNylasId);
  console.log('Provider Nylas Id from localstorage:', providerNylasId);
  console.log('User ID from Redux:', userId);

  useEffect(() => {
    if (code) {
      // The `code` parameter is now URL-encoded before it is passed to the `axios` request.
      encodedCode = encodeURIComponent(code);
      console.log('Encoded Code:', encodedCode); // Log the encoded code before the request
      exchangeCodeForToken(encodedCode, userId);
    } else {
      console.error('No code parameter found in the URL.');
    }
  }, []);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const exchangeCodeForToken = (code) => {
    // Make a request to your Glitch backend server for exchanging the code for the access token
    console.log('Sending Request with Code:', code); // Log the code before the request
    axios
      .post('https://y1xpw543j5.execute-api.us-east-1.amazonaws.com/dev/nylasGmailAuth', {
        code: code, // Use the unencoded `code` directly here
      })
      .then((response) => {
        console.log('Full Response:', response);
        const { access_token, mydata, nylas_calendar } = response.data;
        setMyData(mydata);
        setNylasCelendar(nylas_calendar);
        console.log('My Data:', mydata);
        console.log('Nylas_Calendar:', nylas_calendar);
        console.log('Access Token:', access_token); // Log the access token after successful response
        setUserAccessToken(access_token);

        // Add a delay of 2 seconds before routing to the new page
        setIsRedirecting(false);
      })
      .catch((error) => {
        console.error('Error exchanging code for token:', error);
      });
  };

  const performHasuraMutation = async () => {
    try {
      // Construct the payload
      const payload = {
        access_token: userAccessToken,
        provider_id: providerNylasId,
        user_id: userId,
        calendar_id: nylas_selected_calendar.id,
        calendar_name: nylas_selected_calendar.name,
        calendar_timezone: nylas_selected_calendar.timezone,
        id: myData,
      };

      // Make the POST request to the AWS Lambda API endpoint
      const response = await axios.post(
        'https://tzm7slmcf0.execute-api.us-east-1.amazonaws.com/dev/nylas-config',
        payload,
      );

      console.log('Lambda API Response:', response.data); // Log the response from Lambda API

      setIsSetupComplete(true);
    } catch (error) {
      console.error('Error performing Lambda API request:', error);
    }
  };

  const fetchCalendars = async () => {
    try {
      const response = await axios.get('https://api.nylas.com/calendars', {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
        },
      });
      setCalendars(response.data);
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  };

  const handleNylasCalendarSelect = (calendar) => {
    setNylasSelectedCalendar(calendar);
  };

  useEffect(() => {
    if (isRedirecting) {
      // Simulate a 2-second delay before navigating to the new page
      setTimeout(() => {
        window.location.href = '/providers'; // Route to the new page after 2 seconds
      }, 1000);
    }
  }, [isRedirecting]);

  console.log('Provider Nylas Id from Redux:', providerNylasId);
  console.log('Nylas Access Token', userAccessToken);
  console.log('User ID from Redux:', userId);
  console.log('SELECTED CALENDAR', nylas_selected_calendar);

  const handleCloseTab = () => {
    window.close(); // Close the tab
  };

  return (
    <>
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <FormControl as="fieldset">
              <FormControl.Label as="legend">Calendars</FormControl.Label>
              <Paragraph>Access Token is {userAccessToken}</Paragraph>
              <Paragraph>Provider Id is {providerNylasId}</Paragraph>
              <Paragraph>User Id is {userId}</Paragraph>
              <Paragraph>Select the calendar for this location</Paragraph>
              {nylas_calendar &&
                nylas_calendar.map((calendar) => (
                  <Radio
                    key={calendar.id}
                    id={calendar.id}
                    name="calendar"
                    value={calendar.id}
                    checked={selectedCalendar && selectedCalendar.id === calendar.id}
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const calendar = nylas_calendar.find((cal) => cal.id === selectedId);
                      setSelectedCalendar(calendar);
                      handleNylasCalendarSelect(calendar); // Call your selection handler function
                    }}
                  >
                    {calendar.name} {calendar.timezone}
                  </Radio>
                ))}
            </FormControl>
            <div>
              <Button onClick={performHasuraMutation}>Submit to Hasura</Button>
              <Button onClick={handleCloseTab}>Close Tab</Button>
            </div>
          </Container>
        </div>
      </SiteWrapper>
    </>
  );
};

export default NylasAuthCallback;
