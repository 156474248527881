import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CalendarList = ({ accessToken, onSelectCalendar }) => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  useEffect(() => {
    // Fetch the list of calendars when the component mounts
    fetchCalendars();
  }, []);

  const fetchCalendars = () => {
    axios
      .get('https://api.nylas.com/calendars', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setCalendars(response.data);
      })
      .catch((error) => {
        console.error('Error fetching calendars:', error);
      });
  };

  const handleCalendarSelect = (calendar) => {
    setSelectedCalendar(calendar);
    onSelectCalendar(calendar); // Call the callback function with the selected calendar
  };

  return (
    <div>
      <h2>List of Calendars:</h2>
      <ul>
        {calendars.map((calendar) => (
          <li key={calendar.id}>
            <button type="button" onClick={() => handleCalendarSelect(calendar)}>
              {calendar.name}
            </button>
          </li>
        ))}
      </ul>

      {selectedCalendar && (
        <div>
          <h3>Selected Calendar:</h3>
          <p>Name: {selectedCalendar.name}</p>
          <p>ID: {selectedCalendar.id}</p>
          {/* Add other calendar details here */}
        </div>
      )}
    </div>
  );
};

export default CalendarList;
