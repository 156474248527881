import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Page } from 'tabler-react';
import { Card } from '@contentful/f36-card';
import { Text } from '@contentful/f36-typography';
import SiteWrapper from '../../../../wrappers/SiteWrapper';
import CustomerTable from '../components/CustomerTable';
import providersActions from '../../../../redux/reducers/providers';

function Customers({ match: { params } }) {
  const { uuid } = params;
  const dispatch = useDispatch();

  const providers = useSelector((state) => state.providers.providerCustomers);
  // const { role } = useSelector((state) => state.auth.user);
  // console.log('BOOM2', providers.length);

  // console.log('CUSTOMER TABLE');
  // console.log('ProvCust', providers);

  useEffect(() => {
    dispatch(providersActions.getCustomersByPk(uuid));
  }, [uuid]);

  return (
    <SiteWrapper>
      <Page.Content title="Location Customers">
        <Card>
          <Text> This location has a total of {providers.length} customers </Text>
        </Card>
        <CustomerTable />
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(Customers);
