/* eslint-disable  no-unused-vars, no-template-curly-in-string, prettier/prettier, quotes */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { Page, NavItem, Nav, Site, Button, RouterContextProvider, Text, Icon } from 'tabler-react';
import { IconButton } from '@contentful/f36-button';
import { Layout, Menu, theme } from 'antd';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  ContainerOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { PersonIcon } from '@contentful/f36-icons';
import washnLogo from '../assets/Group1.png';
import authActions from '../redux/reducers/auth';

/**
const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));
 */

const navBarItems = [
  {
    value: 'Providers Locations',
    to: '/providers',
    icon: 'home',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: 'Reports',
    to: '/reports',
    icon: 'monitor',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: 'Upload Images',
    to: '/providers/images',
    icon: 'monitor',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: 'Apps',
    to: '/providers/adminApps',
    icon: 'monitor',
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
];

function SiteWrapper({ children }) {
  const dispatch = useDispatch();
  const {
    authenticated,
    user: { email, role },
  } = useSelector((state) => state.auth);

  if (!authenticated) return null;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function getItem(label, key, icon, type) {
    return {
      key,
      icon,
      label,
      type,
    };
  }

  const { Header, Content, Footer, Sider } = Layout;
  const [redirect, setRedirect] = useState({ redirect: false, to: {} });
const items = [
  getItem('Customers', '1', <TeamOutlined onClick={() => setRedirect({ redirect: true, to: { pathname: `/providers/images` } })}/>),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('Option 3', '3', <ContainerOutlined />),
  getItem('Navigation One', 'sub1', <MailOutlined />, [
    getItem('Option 5', '5'),
    getItem('Option 6', '6'),
    getItem('Option 7', '7'),
    getItem('Option 8', '8'),
  ]),
  getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    getItem('Option 9', '9'),
    getItem('Option 10', '10'),
    getItem('Submenu', 'sub3', null, [getItem('Option 11', '11'), getItem('Option 12', '12')]),
  ]),
];

  return (
    <Site.Wrapper
      headerProps={{
        href: '/',
        alt: 'Washn Logo',
        imageURL: washnLogo,
        navItems: (
          <Nav.Item type="div" className="d-none d-md-flex">
            <IconButton variant="transparent" icon={<PersonIcon variant="primary" />} />
            <Text style={{ fontSize: '.75rem' }}>
              Washn {role} ({email})
            </Text>
            <Button onClick={() => dispatch(authActions.logout())} color="primary" className="ml-5">
              Logout
            </Button>
          </Nav.Item>
        ),
      }}
      navProps={{ itemsObjects: navBarItems }}
      routerContextComponentType={withRouter(RouterContextProvider)}
    >
      {children}
    {/**
      {redirect.redirect && <Redirect push to={redirect.to} />}

    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: 32,
            // margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
          }}
        />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          // marginLeft: 200,
          // alignContent: 'center',
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          style={{
            // padding: 10,
            // margin: '24px 16px 0',
            // overflow: 'initial',
          }}>
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
            }}
          >
        {children}
          </div>
          </Content>
      </Layout>
      </Layout>
    */}
    </Site.Wrapper>
  );
}

export default SiteWrapper;
