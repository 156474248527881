/* eslint-disable react/jsx-props-no-spreading, no-unused-vars, max-len, prettier/prettier */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { Storage } from 'aws-amplify';
import axios from 'axios';
import { Container, Grid, Card, Form } from 'tabler-react';
import { Select, TextInput, FormControl } from '@contentful/f36-forms';
import { Button, ButtonGroup } from '@contentful/f36-button';

import { LockIcon } from '@contentful/f36-icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormHelperText } from '@material-ui/core';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import providersActions from '../../../redux/reducers/providers';
import debounce from '../../../helpers/debounce';

function ProvidersUpdate({ match: { params } }) {
  const { uuid } = params;
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [autocompleteData, setData] = useState([]);
  const [locationEdit, setLocationEdit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const provider = useSelector((state) => state.providers.selectedProvider);
  const [open, setOpen] = useState(false);

  const getAddresses = useMemo(() => debounce((func) => func(), 400), []);

  useEffect(() => {
    dispatch(providersActions.getProviderByPk(uuid));
  }, [uuid]);

  const handleImageChange = (event) => {
    setImage(event.currentTarget.files[0]);
    setImagePreview(URL.createObjectURL(event.currentTarget.files[0]));
  };

  const { hereApiKey } = useSelector((state) => state.profile);
  const [input, setInput] = useState(provider.address || '');

  useEffect(() => {
    setLoading(true);
    getAddresses(async () => {
      try {
        if (input.length < 1) return;
        const { data } = await axios(
          `https://autocomplete.search.hereapi.com/v1/geocode?q=${input}&apiKey=${hereApiKey}&limit=5&at=39.829978517298066,-98.5798078531771&lang=en-US&in=countryCode:USA`,
        );

        setData(data.items);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }, [input, getAddresses]);

  useEffect(() => {
    if (provider.image && !image) {
      try {
        Storage.get(JSON.parse(provider.image).key, { level: 'public' }).then((data) =>
          setImagePreview(data),
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, [provider.image]);

  if (!uuid || provider.id !== uuid) return null;
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            initialValues={{
              name: provider.name || '',
              email: provider.email || '',
              phone: provider.phone || '',
              point_of_contact_name: provider.point_of_contact_name || '',
              point_of_contact_lastname: provider.point_of_contact_lastname || '',
              decision_maker_name: provider.decision_maker_name || '',
              decision_maker_lastname: provider.decision_maker_lastname || '',
              state: provider.state || '',
              city: provider.city || '',
              address: provider.address || '',
              zip: provider.zip || '',
              website: provider.website || '',
              business_type: provider.business_type || '',
              verified: provider.verified ? 'Yes' : 'No',
              address_object: provider.address_object || '',
              location: provider.location || null,
              monday_opening_time: provider.monday_opening_time || null, // Monday
              monday_closing_time: provider.monday_closing_time || null,
              tuesday_opening_time: provider.tuesday_opening_time || null, // Tuesday
              tuesday_closing_time: provider.tuesday_closing_time || null,
              wednesday_opening_time: provider.wednesday_opening_time || null, // Wednesday
              wednesday_closing_time: provider.wednesday_closing_time || null,
              thursday_opening_time: provider.thursday_opening_time || null, // Thursday
              thursday_closing_time: provider.thursday_closing_time || null,
              friday_opening_time: provider.friday_opening_time || null, // Friday
              friday_closing_time: provider.friday_closing_time || null,
              saturday_opening_time: provider.saturday_opening_time || null, // Saturday
              saturday_closing_time: provider.saturday_closing_time || null,
              sunday_opening_time: provider.sunday_opening_time || null, // Sunday
              sunday_closing_time: provider.sunday_closing_time || null,
              open_close: provider.open_close || null,
            }}
            validate={(values) => {
              const errors = {};
              if (values.name.length === 0) errors.name = 'Required';
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email';
              }
              if (values.phone.length === 0) errors.phone = 'Required';
              if (values.state.length === 0 || values.state === 'Please select a state') {
                errors.state = 'Required';
              }
              if (values.city.length === 0) errors.city = 'Required';
              if (values.address.length === 0) errors.address = 'Required';
              if (values.zip.length === 0 || values.zip.length < 5) {
                errors.zip = 'Required and must have 5 digits long';
              }
              if (!values.address_object && !provider.location) errors.address_object = true;

              if (values.point_of_contact_name.length === 0) {
                errors.point_of_contact_name = 'Required';
              }
              if (values.point_of_contact_lastname.length === 0) {
                errors.point_of_contact_lastname = 'Required';
              }
              if (values.decision_maker_name.length === 0) {
                errors.decision_maker_name = 'Required';
              }
              if (values.decision_maker_lastname.length === 0) {
                errors.decision_maker_lastname = 'Required';
              }

              if (values.business_type.length === 0) {
                errors.business_type = 'Required';
              }

              return errors;
            }}
            validateOnChange
            onSubmit={(values) => {
              dispatch(
                providersActions.updateProviderByPk({
                  ...values,
                  id: uuid,
                  image,
                  userId: user.id,
                  oldImageKey: provider.image ? provider.image : undefined,
                  userRole: user.role,
                }),
              );
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setValues }) => (
              <form className="card" onSubmit={handleSubmit} autoComplete="off">
                <Card.Header>
                  <Card.Title>Update Provider Location</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col sm={6} md={6} className="col-12">
                      <FormControl>
                        <FormControl.Label>Location Name</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          type="text"
                          placeholder="Location Name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          error={!!errors.name}
                          tick={!errors.name && values.name.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={6} md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Email</FormControl.Label>
                        <TextInput
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          error={!!errors.email}
                          tick={!errors.email && values.email.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={6} md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Phone</FormControl.Label>
                        <TextInput
                          type="tel"
                          placeholder="Phone*"
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                          error={!!errors.phone}
                          tick={!errors.phone && values.phone.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Point of Contact Firstname</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Firstname"
                          name="point_of_contact_name"
                          onChange={handleChange}
                          value={values.point_of_contact_name}
                          error={!!errors.point_of_contact_name}
                          tick={
                            !errors.point_of_contact_name && values.point_of_contact_name.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Point of Contact Lastname</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Lastname"
                          name="point_of_contact_lastname"
                          onChange={handleChange}
                          value={values.point_of_contact_lastname}
                          error={!!errors.point_of_contact_lastname}
                          tick={
                            !errors.point_of_contact_lastname &&
                            values.point_of_contact_lastname.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Decision Maker Firstname</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Firstname*"
                          name="decision_maker_name"
                          onChange={handleChange}
                          value={values.decision_maker_name}
                          error={!!errors.decision_maker_name}
                          tick={
                            !errors.decision_maker_name && values.decision_maker_name.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Decision Maker Lastname</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Lastname*"
                          name="decision_maker_lastname"
                          onChange={handleChange}
                          value={values.decision_maker_lastname}
                          error={!!errors.decision_maker_lastname}
                          tick={
                            !errors.decision_maker_lastname &&
                            values.decision_maker_lastname.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={4} auto className="mb-3 col-12">
                      {!locationEdit ? (
                        <FormControl>
                          <FormControl.Label>Location Address</FormControl.Label>
                          <Form.InputGroup>
                            <TextInput disabled value={values.address} />
                            <TextInput.Group>
                              {user.role !== 'worker' && (
                                <Button /// FIX EDIT AND CANCEL BUTTON
                                  // RootComponent="button"
                                  variant="positive"
                                  onClick={() => {
                                    setLocationEdit(true);
                                    setValues((val) => ({
                                      ...val,
                                      state: '',
                                      city: '',
                                      zip: '',
                                    }));
                                  }}
                                >
                                  Edit
                                </Button>
                              )}
                            </TextInput.Group>
                          </Form.InputGroup>
                        </FormControl>
                      ) : (
                        <Form.Group label="Enter the complete address and choose one of the options">
                          <Form.InputGroup>
                            <div style={{ display: 'flex', width: '100%' }}>
                              <Autocomplete
                                id="free-solo-2-demo"
                                options={autocompleteData}
                                inputValue={input}
                                loading={loading}
                                open={open}
                                onOpen={() => {
                                  setOpen(true);
                                }}
                                onClose={() => {
                                  setOpen(false);
                                }}
                                getOptionSelected={(option, value) => option.title === value.title}
                                onChange={(event, newValue) => {
                                  const {
                                    address: { stateCode, postalCode, city, houseNumber, street },
                                  } = newValue;

                                  if (houseNumber) {
                                    setValues((form) => ({
                                      ...form,
                                      city,
                                      state: stateCode ? `${stateCode}` : city,
                                      zip: postalCode.split('-')[0],
                                      address_object: newValue,
                                      address: `${houseNumber} ${street}`,
                                    }));
                                  } else {
                                    setValues((form) => ({
                                      ...form,
                                      city,
                                      state: stateCode ? `${stateCode}` : city,
                                      zip: postalCode.split('-')[0],
                                      address_object: newValue,
                                      address: street,
                                    }));
                                  }
                                }}
                                style={{ flex: 9 }}
                                onInputChange={(event, newInputValue) => {
                                  setInput(newInputValue);
                                }}
                                getOptionLabel={(option) => option.title}
                                filterOptions={(x) => x}
                                renderInput={(param) => (
                                  <div
                                    ref={param.InputProps.ref}
                                    style={{ borderRadius: 5, width: '100%', height: '38px' }}
                                  >
                                    <input
                                      type="search"
                                      autoComplete="new-password"
                                      placeholder="i.e: 1 Liberty St, New York, NY 10005"
                                      {...param.inputProps}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        padding: '6px 12px',
                                        border: '1px solid rgba(0, 40, 100, 0.12)',
                                        borderRadius: 5,
                                      }}
                                    />
                                    {errors.address_object && (
                                      <FormHelperText error>
                                        You must enter an address above and choose one of the
                                        options.
                                      </FormHelperText>
                                    )}
                                  </div>
                                )}
                              />
                              <div style={{ flex: 1 }}>
                                <Button
                                  spacing="spacingM"
                                  // RootComponent="button"
                                  variant="negative"
                                  onClick={() => {
                                    setLocationEdit(false);
                                    setValues((val) => ({
                                      ...val,
                                      state: provider.state,
                                      city: provider.city,
                                      zip: provider.zip,
                                    }));
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </Form.InputGroup>
                        </Form.Group>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>City</FormControl.Label>
                        <TextInput
                          isReadOnly
                          // disabled
                          type="text"
                          placeholder="City*"
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                          error={!!errors.city}
                          tick={!errors.city && values.city.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>State</FormControl.Label>
                        <TextInput
                          isReadOnly
                          // disabled
                          type="text"
                          placeholder="State*"
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                          error={!!errors.state}
                          tick={!errors.state && values.state.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Zipcode</FormControl.Label>
                        <TextInput
                          isReadOnly
                          // disabled
                          type="text"
                          placeholder="ZIP Code*"
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                          error={!!errors.zip}
                          tick={!errors.zip && values.zip.length > 0}
                        />
                        {!!errors.zip && <FormHelperText error>{errors.zip}</FormHelperText>}
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Business Type</FormControl.Label>
                        <Select
                          id="optionSelect-controlled"
                          // name="optionSelect-controlled"
                          onChange={handleChange}
                          value={values.business_type}
                          name="business_type"
                        >
                          <Select.Option>---</Select.Option>
                          <Select.Option value="Individual">Individual</Select.Option>
                          <Select.Option value="Corporation">Corporation</Select.Option>
                          <Select.Option value="LLC">LLC</Select.Option>
                          <Select.Option value="Sole Proprietor">Sole Proprietor</Select.Option>
                        </Select>
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Website</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Website"
                          name="website"
                          onChange={handleChange}
                          value={values.website}
                          tick={values.website.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Location Logo</FormControl.Label>
                        <TextInput
                          type="file"
                          name="image"
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} className="col-12">
                      {imagePreview && (
                        <>
                          <Card style={{ maxWidth: '25%' }}>
                            <img src={imagePreview} alt="Uploaded" />
                          </Card>
                        </>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Card.Header>
                  <Card.Title>Provider Buisness Hours</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col sm={1} md={2} className="col-6">
                        <FormControl>
                          <FormControl.Label>Mon Open</FormControl.Label>
                          <TextInput
                            autoComplete="off"
                            placeholder="08:00"
                            name="monday_opening_time"
                            onChange={handleChange}
                            value={values.monday_opening_time}
                          />
                        </FormControl>
                      </Grid.Col>
                      <Grid.Col sm={1} md={2} className="col-6">
                        <FormControl>
                          <FormControl.Label>Mon Close</FormControl.Label>
                          <TextInput
                            autoComplete="off"
                            placeholder="18:00"
                            name="monday_closing_time"
                            onChange={handleChange}
                            value={values.monday_closing_time}
                          />
                        </FormControl>
                      </Grid.Col>
                      <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Tues Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="tuesday_opening_time"
                          onChange={handleChange}
                          value={values.tuesday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Tues Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="tuesday_closing_time"
                          onChange={handleChange}
                          value={values.tuesday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Wed Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="wednesday_opening_time"
                          onChange={handleChange}
                          value={values.wednesday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Wed Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="wednesday_closing_time"
                          onChange={handleChange}
                          value={values.wednesday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                  <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Thur Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="thursday_opening_time"
                          onChange={handleChange}
                          value={values.thursday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Thur Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="thursday_closing_time"
                          onChange={handleChange}
                          value={values.thursday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Frid Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="friday_opening_time"
                          onChange={handleChange}
                          value={values.friday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Fri Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="friday_closing_time"
                          onChange={handleChange}
                          value={values.friday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Sat Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="saturday_opening_time"
                          onChange={handleChange}
                          value={values.saturday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Sat Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="saturday_closing_time"
                          onChange={handleChange}
                          value={values.saturday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Sun Open</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="08:00"
                          name="sunday_opening_time"
                          onChange={handleChange}
                          value={values.sunday_opening_time}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={1} md={2} className="col-6">
                      <FormControl>
                        <FormControl.Label>Sun Close</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          // type="datetime-local"
                          placeholder="18:00"
                          name="sunday_closing_time"
                          onChange={handleChange}
                          value={values.sunday_closing_time}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                </Card.Body>
                <Card.Footer className="text-right">
                  <ButtonGroup variant="spaced" spacing="spacingM">
                    <Button onClick={() => window.history.back()} variant="secondary">
                      Cancel
                    </Button>
                    <Button type="submit" variant="positive">
                      Update Provider Location
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersUpdate);
